<template>
  <div class="login">
    <div class="card overflow-hidden">
      <div class="bg-soft-primary">
        <div class="row">
          <div class="col-12">
            <div class="text-primary p-4">
              <h5 class="text-primary">Forgot your password?</h5>
              <p v-if="step !== 3">
                Enter your email address below and we’ll send you a code to
                allow you to reset your password.
              </p>
              <p v-if="step === 3">
                Please enter a new password below. Once you save this you will
                be asked to log in using your new details.
                <br /><br />
                Your new password must be a minimum of 8 characters, include
                at-least one number and one uppercase character
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div>
          <router-link tag="a" to="/">
            <div class="avatar-md profile-user-wid mb-4">
              <span class="avatar-title rounded-circle bg-white">
                <img src="@/assets/images/logo.png" alt height="34" />
              </span>
            </div>
          </router-link>
        </div>
        <error :err="error" variant="danger" class="mt-3" />

        <!-- Step 1 -->
        <b-form class="p-2" @submit.prevent="sendEmail" v-if="step === 1">
          <b-form-group
            id="input-group-1"
            label="Email address"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="email"
              type="text"
              placeholder="Enter your email address"
            ></b-form-input>
          </b-form-group>
          <div class="mt-3">
            <ebp-button
              :loading="loading"
              type="submit"
              variant="primary"
              className="btn-block"
              >Email me a password reset code</ebp-button
            >
          </div>
        </b-form>
        <!-- Step 2 -->
        <b-form class="p-2" @submit.prevent="submitCode" v-if="step === 2">
          <b-form-group
            id="input-group-1"
            label="Please enter the 4 digit code sent on your email"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="code"
              type="text"
              :placeholder="$t('enter-code')"
            ></b-form-input>
          </b-form-group>
          <div class="mt-3">
            <ebp-button
              :loading="loading"
              type="submit"
              variant="primary"
              className="btn-block"
              >{{ $t("submit-code") }}</ebp-button
            >
          </div>
        </b-form>

        <!-- Step 3 -->
        <b-form class="p-2" @submit.prevent="resetPassword" v-if="step === 3">
          <b-form-group
            id="input-group-1"
            :label="$t('new-password')"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="password"
              type="password"
              :placeholder="$t('enter-new-password')"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            :label="$t('confirm-password')"
            label-for="confirm-password"
          >
            <b-form-input
              id="confirm-password"
              v-model="confirmPassword"
              type="password"
              :placeholder="$t('enter-confirm-password')"
            ></b-form-input>
          </b-form-group>
          <div class="mt-3">
            <ebp-button
              :loading="loading"
              type="submit"
              variant="primary"
              className="btn-block"
              >Save new password</ebp-button
            >
          </div>
        </b-form>
      </div>
    </div>

    <div class="mt-5 text-center">
      <p>
        <router-link
          tag="a"
          :to="{ name: 'Login' }"
          class="font-weight-medium text-primary mr-2"
          >{{ $t("login") }}</router-link
        >
        <span class="font-weight-bold">or</span>
        <router-link
          tag="a"
          :to="{ name: 'Register' }"
          class="font-weight-medium text-primary ml-2"
          >{{ $t("register") }}</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t("login")
    };
  },
  data() {
    return {
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
      error: null,
      loading: false,
      step: 1
    };
  },
  computed: {
    subtitle() {
      let subtitle = "";
      switch (this.step) {
        case 1:
          subtitle = "forgot-password-enter-email";
          break;
        case 2:
          subtitle = "forgot-password-enter-code";
          break;
        case 3:
          subtitle = "forgot-password-enter-password";
          break;
      }

      return subtitle;
    }
  },
  methods: {
    async sendEmail() {
      this.error = null;
      this.loading = true;

      try {
        await this.$store.dispatch("forgot_password/sendCode", this.email);
        this.step = 2;
      } catch (err) {
        this.error = this.errorRes(err);
      }

      this.loading = false;
    },
    async submitCode() {
      this.error = null;
      this.loading = true;

      try {
        await this.$store.dispatch("forgot_password/submitCode", {
          email: this.email,
          code: this.code
        });
        this.step = 3;
      } catch (err) {
        this.error = this.errorRes(err);
      }

      this.loading = false;
    },
    async resetPassword() {
      this.error = null;
      const { password, confirmPassword } = this;
      if (!password) {
        this.error = "validation-errors.password-required";
      } else if (password !== confirmPassword) {
        this.error = "validation-errors.passwords-dont-match";
      } else if (password.length < 8) {
        this.error = "The new password must be at-least 8 characters long";
      } else if (!/\d/.test(password)) {
        this.error = "The new password must contain at-least one numeric digit";
      } else if (!/[A-Z]/.test(password)) {
        this.error =
          "The new password must contain at-least one uppercase character";
      } else {
        this.loading = true;

        try {
          await this.$store.dispatch("forgot_password/resetPassword", {
            email: this.email,
            code: this.code,
            password
          });
          this.$router.push({ name: "Login" });
        } catch (err) {
          this.error = this.errorRes(err);
        }

        this.loading = false;
      }
    }
  }
};
</script>
